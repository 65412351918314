.file-not-found-section .file-not-found-container{
    position:relative;
    background:#fff;
    min-height:100vh;
    width:100%;
    color:#00779f;
    display:flex;
    align-items:center;
    justify-content:center;
  }
  
  .file-not-found-section .container-inside{
    width:70%;
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction:column;
  }
  .file-not-found-section .container-inside h1{
    margin: 0;
    font-size:clamp(160px,50vw,320px);
    background: linear-gradient(to top,#00779f,#ffc371);
    background-clip:text;
    -webkit-background-clip:text;
    -webkit-text-fill-color:transparent;

    
  }
  .file-not-found-section .container-inside h4{
    font-size:30px;
    letter-spacing:3px;
  }
  
  .file-not-found-section  a{
    text-decoration:none;
    margin-top:50px;
    border: 3px solid transparent;
      border-image: linear-gradient(to right, #00779f 0%, #ffc371 100%);
      border-image-slice: 1;
      background-color:#fff;
      color:#000;
      line-height: 1;
      text-decoration: none;
      text-transform: uppercase;
      letter-spacing: 1px;
      cursor: pointer;
      padding: 0.7rem 1.3rem;
      transition: none;
    
  }
  .file-not-found-section a:hover{
    background-color:#00779f;
     border-image: linear-gradient(to right, #83b8ca 0%, #00779f 100%);
        border-image-slice: 1;
        color: #fff;
        outline: none;
        box-shadow: 0px 0px 5px 0px rgba(26,26,26,0.6);
  }