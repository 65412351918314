
.wrapper1{
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(16rem, 22rem));
    gap: 6rem;
    justify-content: center;
    padding: 8rem 8rem 8rem 8rem;
}


.card1{
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    transition: transform 200ms ease-in;
}

.card__img1{

    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    transition: transform 200ms ease-in;
    max-width: 100%;
    height: auto;
    padding-bottom: 20px;
    object-fit: cover;
}

.card__img1:hover{
    opacity: .5;
}

.card__title1{
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    transition: transform 200ms ease-in;
    text-align: center;
    font-size: 2em;
    font-weight: 200;    
}


.card__title1 a {
    text-decoration: none;
    color: #000000;
    font-family: "Roboto", Sans-serif;
}


.card__description1{
    text-align: center;
    color: #54595f;
    font-family: "Roboto", Sans-serif;
    font-size: 0.8em;
    font-weight: 300;
    line-height: 1.8em;
    letter-spacing: 0px;
    padding-top: 30px;
}
