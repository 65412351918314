   h1,
   h2,
   h3,
   h4,
   h5,
   h6 {
     padding: 0px;
     margin: 0px;
   }

   .font-size {
     font-size: 2em !important;
   }

   a {
     text-decoration: none;
     color: #0078a1;
   }

   h1 {
     font-weight: 400;
   }

   h2 {
     font-weight: 300;
     line-height: 45px;
   }

   h3 {
     font-size: 30px;
     font-weight: 300;
   }

   h4 {
     font-size: 24px;
     font-weight: 300;
   }

   h5 {
     font-size: 22px;
     font-weight: 400;
     margin-bottom: 10px;
   }

   h6 {
     font-size: 20px;
     font-weight: 300;
   }

   p {
     margin-bottom: 20px;
   }

   .cnt-block {
     padding: 120px 0px;
   }

   .padding-lg {
     padding: 120px 0px !important;
   }

   .pb-0 {
     padding-bottom: 0px !important;
   }

   .mt-100 {
     margin-top: 100px;
   }

   .mb-50 {
     margin-bottom: 50px !important;
   }

   .pl-0 {
     padding: 0 !important;
   }

   .pr-0 {
     padding: 0 !important;
   }

   .mandatoryField {
     color: #00779f;
     position: relative;
     margin-left: 5px;
   }

   .headWrap {
     display: flex;
     flex-direction: row;
     background-position: center right;
     background-repeat: no-repeat;
     background-size: cover;
     mix-blend-mode: screen;
     transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
     overflow: hidden;
     position: relative;
     margin: 0 10%;
   }

   .header-title {
     font-size: 1.3em;
     font-weight: 300;
   }

   .textWhite {
     color: #fff !important;
     border-color: #fff !important;
   }

   .leftTextAlign {
     text-align: left !important;
   }

   .rightTextAlign {
     text-align: right !important;
   }

   .contentTitle {
     position: relative;
     font-weight: 700;
   }

   .contentTitle:after {
     content: '';
     position: absolute;
     left: 0;
     right: 0;
     top: 100%;
     margin: 10px auto;
     width: 10%;
     height: 5px;
     margin-top: 20px;
     background: #00779f;
   }

   .contentTitleLeft {
     position: relative;
     font-weight: 700;
     text-align: left;
   }

   .contentTitleLeft:after {
     content: '';
     position: absolute;
     left: 0;
     top: 100%;
     margin: 10px auto;
     width: 10%;
     height: 5px;
     margin-top: 20px;
     background: #00779f;
   }

   .title-container {
     margin-bottom: 60px;
   }

   .cnt-block p {
     margin-bottom: 1.6em;
     line-height: 1.7em;
   }

   .thin-content {
     font-weight: 100 !important;
   }

   .small-content span {
     font-size: 0.8em;
     text-transform: initial;
   }

   .bannerSubtitle {
     font-size: 48px;
     font-weight: 300;
   }

   .textShadow {
     text-shadow: 0px 0px 10px rgb(0 0 0 / 30%);
   }

   .listContainer {
     padding: 0px 20px;
     text-align: left;
     line-height: 1.7em;
   }

   .textfieldContainer {
     padding: 5px 24px;
     width: 100%;
   }

   .warranty-form label {
     font-size: 1.1em;
     font-weight: 600;
     text-transform: uppercase;
     letter-spacing: 1px;
     color: #000000;
   }

   .warranty-form input[type=text],
   .MuiSelect-select {
     border: 1px solid #7a7a7a !important;
     border-radius: 0 !important;
     padding: 10px !important;
   }

   .request_form input[type=text] {
     border: 1px solid #ffffff !important;
     border-radius: 0 !important;
     padding: 10px !important;
     color: #ffffff !important;
     background-color: rgba(255, 255, 255, 0.2) !important;
     ;
   }

   .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
     color: #7a7a7a !important;
   }

   .request_form label {
     font-size: 1.1em;
     font-weight: 600;
     text-transform: uppercase;
     letter-spacing: 1px;
     color: #ffffff;
   }

   .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {
     color: #ffffff !important;
   }

   .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input {

     border: 1px solid #7a7a7a !important;
     border-radius: 0 !important;
     padding: 10px !important;
   }

   input[type=number] {
     border: 1px solid #7a7a7a !important;
     border-radius: 0 !important;
     padding: 10px !important;
   }

   input[type=number]::-webkit-inner-spin-button,
   input[type=number]::-webkit-outer-spin-button {
     -webkit-appearance: none;
     margin: 0;
   }

   input[type=email] {
     border: 1px solid #7a7a7a !important;
     border-radius: 0 !important;
     padding: 10px !important;
   }

   .grid-padding .css-1qw96cp {
     padding-left: 0px !important;
     padding-right: 0px !important;
   }


   .grid-padding .css-46bh2p-MuiCardContent-root {
     padding-left: 0px !important;
     padding-right: 0px !important;
   }

   .videolink {
     font-size: 18px;
     cursor: pointer;
     transition: #fff 0.3s ease;
     margin-bottom: 10px;

   }

   .videolink:hover {
     color: #00779f;

   }

   .custom-icon {
     color: #00779f;
     padding-right: 10px;
   }

   .videolink-padding {
     padding-left: 100px;
   }

   .poster-container {
     position: relative;
     width: 100%;
     display: flex;
     justify-content: center;
     align-items: center;
   }

   .poster img {
     width: 100%;
     height: auto;
   }

   .play-button {
     position: absolute;
     top: 50%;
     left: 50%;
     transform: translate(-50%, -50%);
     background-color: transparent;
     border: none;
     color: #fff;
     font-size: 24px;
     padding: 10px 20px;
     cursor: pointer;
     outline: none;
   }


   .loader-overlay {
     position: fixed;
     top: 0;
     left: 0;
     right: 0;
     bottom: 0;
     display: flex;
     justify-content: center;
     align-items: center;
     background-color: rgba(0, 0, 0, 0.3);
     /* Semi-transparent overlay */
     z-index: 9999;
     /* Adjust the z-index to ensure the loader appears above other elements */
   }

   .loader {
     width: 30px;
     height: 30px;
     border: 4px solid #f3f3f3;
     border-top: 4px solid #00779f;
     border-radius: 50%;
     animation: spin 1s linear infinite;
   }

   @keyframes spin {
     0% {
       transform: rotate(0deg);
     }

     100% {
       transform: rotate(360deg);
     }
   }

   .loader-text {
     margin-top: 10px;
     font-size: 14px;
     color: #333;
   }


   @media (max-width: 768px) {

     .cnt-block {
       padding: 20px 0px;
     }

     .videolink-padding {
       padding: 0px 40px;
     }
   }

   @media (min-width: 769px)and (max-width: 991px) {
     .grid-padding-tab .css-101e1tt-MuiPaper-root-MuiCard-root {
       margin-left: 1% !important;
       margin-right: 1% !important;
     }
   }


   /*  DectecProfessional */

   .professionalProgram {
     background-image: url("https://dectecstorageprod.blob.core.windows.net/website/images/technical/1.jpg");
     background-position: center center;
     background-size: cover;
     min-height: 70vh;
     background-repeat: no-repeat;
     display: flex;
     align-items: center;

   }

   /*  Detail Drawing */

   .triangle {
     background-color: #66696c;
     position: relative;
     height: 48vh;
     clip-path: polygon(50% 98%, 100% 58%, 100% 0, 0 0, 0 58%);
   }

   /*  Installation products */


   .installation-section-third {
     background: url("https://dectecstorageprod.blob.core.windows.net/website/images/products/become-an-installer-template-min.jpg");
     background-blend-mode: multiply;
     background-color: #ffffff;
     background-size: cover;
     background-repeat: no-repeat;
     background-position: top center;
     background-attachment: initial;
     width: 100%;
     position: relative;
     opacity: 0.85;
     padding: 30px 0px 140px 0px;
   }

   /*  Spacification */

   .technical_img {
     border-style: solid;
     border-width: 1px 1px 1px 1px;
     border-color: var(--e-global-color-text);
   }

   /*  Technical Request */

   .formgridRight {
     padding: 0 !important;
     background-size: cover;
     background-repeat: no-repeat;
     min-height: 100vh;
   }

   .formgridLeft {
     background: linear-gradient(0deg, rgb(44 45 45 / 97%), rgb(0 0 0 / 76%)), url(https://dectecstorageprod.blob.core.windows.net/website/images/technical/DecTec-Logo-02-1-e1544646476663.png) repeat;
     background-size: cover;
     background-repeat: no-repeat;
     background-color: #333333;
     padding: 0 7% !important;

   }

   /* Technical Request */

   .content-padding-left {
     align-items: flex-start !important;
     margin-left: 10%;
   }

   .content-padding-right {
     align-items: flex-end !important;
     margin-right: 10%;
   }

   .pdflink {
     font-size: 0.9em;
     cursor: pointer;
     transition: #fff 0.3s ease;
     margin-bottom: 10px;
   }

   /* bitmovin */
   .bitmovinplayer-poster {
    background-size: cover!important;
   }

   @media (max-width: 768px) {

     .content-padding-left {
       align-items: center !important;
       margin: 10%;
       justify-content: center;
     }

     .content-padding-right {
       align-items: center !important;
       margin: 10%;
     }

     .paragraph {
       text-align: center !important;
       margin: 0 5% !important;
     }

     .rightTextAlign {
       text-align: center !important;
     }

     .leftTextAlign {
       text-align: center !important;
     }



     .one {
       order: 1
     }

     .two {
       order: 2
     }

     .three {
       order: 4
     }

     .four {
       order: 3
     }

     .five {
       order: 5
     }

     .six {
       order: 6
     }

     .seven {
       order: 8
     }

     .eight {
       order: 7
     }

     .nine {
       order: 9
     }

     .ten {
       order: 10
     }

     .eleven {
       order: 12
     }

     .twelve {
       order: 11
     }

     .thirteen {
       order: 13
     }

     .fourteen {
       order: 14
     }

     .fifteen {
       order: 16
     }

     .sixteen {
       order: 15
     }

     .seventeen {
       order: 17
     }

     .eighteen {
       order: 18
     }

     .css-101e1tt-MuiPaper-root-MuiCard-root {
       margin-left: 10% !important;
       margin-right: 10% !important;
     }

     .grid-padding-tab .css-101e1tt-MuiPaper-root-MuiCard-root {
       margin-left: 20px !important;
       margin-right: 20px !important;
     }
   }

   .tilelayer {
     background-color: white !important;
     zoom: 0% !important;
   }

   .tooltip {
     width: 100;
   }

   .leaflet-interactive:focus {
     outline: none !important;
   }

   .leaflet-popup-tip-container {
     display: none;
   }

   .resposiveBox {
     position: absolute;
     top: 50%;
     left: 50%;
     transform: translate(-50%, -50%);
     background-color: white;
     padding: 1%;
     width: max-content;
   }

   .mapdiv {
     display: flex;
     flex-direction: row;
     margin-top: -220px;
     position: relative;
   }

   @media (prefers-reduced-motion: no-preference) {
     .App-logo {
       animation: App-logo-spin infinite 20s linear;
     }
   }

   @media (min-width: 250px)and (max-width: 315px) {
     .tilelayer {
       zoom: 20% !important;
     }

     .resposiveBox {
       width: 80%;
       padding: 4%;
     }

     .mapdiv {
       margin-top: -35px !important;
     }

     .small-font {
       font-size: 20px !important;
     }

     .padding-lg {
       padding: 30px 0px !important;
     }
   }

   @media (min-width: 320px)and (max-width: 350px) {
     .tilelayer {
       zoom: 27% !important;
     }

     .resposiveBox {
       width: 80%;
       padding: 4%;
     }

     .mapdiv {
       margin-top: -60px !important;
     }

   }

   @media (min-width: 350px)and (max-width: 375px) {
     .tilelayer {
       zoom: 27% !important;
     }

     .resposiveBox {
       width: 80%;
       padding: 4%;
     }

     .mapdiv {
       margin-top: -40px !important;
     }

   }


   @media (min-width: 375px)and (max-width: 400px) {
     .tilelayer {
       zoom: 30% !important;
     }

     .resposiveBox {
       width: 80%;
       padding: 4%;
     }

     .mapdiv {
       margin-top: -75px !important;
     }
   }


   @media (min-width: 400px)and (max-width: 425px) {
     .tilelayer {
       zoom: 35% !important;
     }

     .resposiveBox {
       width: 80%;
       padding: 4%;
     }

     .mapdiv {
       margin-top: -90px !important;
     }


     .mapHeight {
       height: 80vh !important;
     }

   }

   @media (min-width: 425px)and (max-width: 450px) {
     .tilelayer {
       zoom: 38% !important;
     }

     .resposiveBox {
       width: 80%;
       padding: 4%;
     }

     .mapdiv {
       margin-top: -115px !important;
     }
   }

   @media (min-width: 450px)and (max-width: 500px) {
     .tilelayer {
       zoom: 40% !important;
     }

     .resposiveBox {
       width: 80%;
       padding: 4%;
     }

     .mapdiv {
       margin-top: -100px !important;
     }
   }


   @media (min-width: 500px)and (max-width: 550px) {
     .tilelayer {
       zoom: 43% !important;
     }

     .resposiveBox {
       width: 80%;
       padding: 4%;
     }

     .mapdiv {
       margin-top: -100px !important;
     }
   }

   @media (min-width: 550px)and (max-width: 600px) {
     .tilelayer {
       zoom: 50% !important;
     }

     .resposiveBox {
       width: 80%;
       padding: 4%;
     }
   }

   @media (min-width: 600px)and (max-width: 700px) {
     .tilelayer {
       zoom: 60% !important;
     }
   }

   @media (min-width: 700px)and (max-width: 730px) {
     .tilelayer {
       zoom: 60% !important;
     }

     .mapHeight {
       height: 60vh !important;
     }

     .mapdiv {
       margin-top: -165px !important;
     }
   }

   @media (min-width: 730px)and (max-width: 768px) {
     .tilelayer {
       zoom: 65% !important;
     }
   }

   @media (min-width: 768px)and (max-width: 800px) {
     .tilelayer {
       zoom: 68% !important;
     }

     .mapdiv {
       margin-top: -170px !important;
     }

     .mapHeight {
       height: 70vh !important;
     }

   }

   @media (min-width: 800px) and (max-width: 845px) {
     .tilelayer {
       zoom: 70% !important;
     }

     .mapdiv {
       margin-top: -190px !important;
     }

     .mapHeight {
       height: 60vh !important;
     }
   }

   @media (min-width: 845px) and (max-width: 911px) {
     .tilelayer {
       zoom: 72% !important;
     }
   }

   @media (min-width: 911px) and (max-width: 991px) {
     .tilelayer {
       zoom: 80% !important;
     }

     .mapdiv {
       margin-top: -230px !important;
     }

     .mapHeight {
       height: 50vh !important;
     }
   }

   @media (min-width: 991px) and (max-width: 1024px) {
     .tilelayer {
       zoom: 85% !important;
     }

     .mapdiv {
       margin-top: -125px !important;
     }

     .mapHeight {
       height: 120vh !important;
     }

     .mapHeightAlaska {
       height: 60vh !important;
     }
   }






   @media (max-width: 991px) {

     .my-container {
       display: flex;
       flex-flow: column;
     }

     #one {
       order: 1
     }

     #two {
       order: 2
     }

     #three {
       order: 4
     }

     #four {
       order: 3
     }

     #five {
       order: 5
     }

     #six {
       order: 6
     }

     #seven {
       order: 8
     }

     #eight {
       order: 7
     }

     #nine {
       order: 9
     }

     #ten {
       order: 10
     }

     #eleven {
       order: 12
     }

     #twelve {
       order: 11
     }

     #thirteen {
       order: 13
     }

     #fourteen {
       order: 14
     }

     #fifteen {
       order: 16
     }

     #sixteen {
       order: 15
     }

     #seventeen {
       order: 17
     }

     #eighteen {
       order: 18
     }

     /* .css-101e1tt-MuiPaper-root-MuiCard-root {
       margin-left: 1% !important;
       margin-right: 1% !important;
     } */

     /* .grid-padding-tab .css-101e1tt-MuiPaper-root-MuiCard-root {
       margin-left: 1% !important;
       margin-right: 1% !important;
     } */

     /* .grid-padding .css-46bh2p-MuiCardContent-root {

       padding-left: 15px !important;
       padding-right: 15px !important;

     } */

     .hideOnMD {
       display: none !important;
     }

     .rightTextAlign {
       text-align: center !important;
     }

     .leftTextAlign {
       text-align: center !important;
     }

     .content-padding-right {
       align-items: center !important;
       margin: 0 5%;
     }

     .content-padding-left {
       align-items: center !important;
       margin: 0 5%;
     }

   }