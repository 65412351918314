.accesories-section-third {
    background: url("https://dectecstorageprod.blob.core.windows.net/website/images/products/become-an-installer-template-min.jpg");
    background-blend-mode: multiply;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    background-color: #ffffff;
    /* clip-path: polygon(50% 80%, 100% 55%, 100% 0, 0 0, 0 55%); */
    /* height: 90vh; */
    width: 100%;
    position: relative;
    background-attachment: initial;
    opacity: 0.85;
}

.accessories-heading {
    padding: 25px 0px 25px 0px !important;
    font-size: 2em !important;
}

.accessories-heading-padding {
    padding-top: 151px;
}

.accessories-heading-width {
    width: 80% !important;
}

.accessories-image-box {
    width: 100%;
    /* height: 40vh; */
}

.accessories-card-margin {
    margin: 20px 10px 20px 0px !important;
    width: 10%;
}

.accessories-card-align-left {
    align-items: flex-start !important;
    text-align: left !important;
    padding-right: 60px !important;
}

.accessories-align-right {
    align-items: flex-end !important;
    text-align: right !important;
    padding-left: 30px !important;
    width: auto !important;
}

.accessories-Card-button {
    margin: 20px 50px;
}

.accessories-font-size {
    font-size: 14px !important;
}

.cardLeft {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;
    padding: 0px 50px;
}

.cardRight {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    padding: 0px 50px;
}
.card-para-access {
    font-size: 14px;
    font-weight: 300 !important;
    line-height: 1.8em !important;
    color: #54595f;
    padding-top: 30px;
}

.card-paragraph {
    color: #54595f;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.8em;
    letter-spacing: 0px;
}

.btn-wrap {
    display: flex;
    width: 100%;
}

.justify-start {
    justify-content: start !important;
}

.justify-end {
    justify-content: end !important;
}

.m-r-10 {
    margin-right: 10px !important;
}

.m-l-10 {
    margin-left: 10px !important;
}


.elementor-shape {
    width: 100%;    
    position: absolute;    
    bottom: -6px;    
}

.text_border-bottom {
    position: relative;
    padding-bottom: 20px !important;
}

.text_border-bottom :after {
    position: absolute;
}

@media (max-width:991px) {
    .Section-four-element-first {
        padding: 10% 0%;
    }

    .accessories-image-box {
        height: 40vh;
        object-fit: cover;
    }



    .cardRight {
        align-items: center;
        text-align: center;
        padding: 0px 30px;
    }

    .cardLeft {
        align-items: center;
        text-align: center;
        padding: 0px 30px;
    }

    .justify-start {
        justify-content: center !important;
    }
    
    .justify-end {
        justify-content: center !important;
    }
    
    .m-r-10 {
        margin-right: 40px !important;
    }
    
    .m-l-10 {
        margin-left: 40px !important;
    }
    
    .card-heading-font-size{
        font-size: 1.5em;
    }
}

@media (max-width:768px) {
    .m-r-10 {
        margin-right: 0px !important;
    }
    
    .m-l-10 {
        margin-left: 0px !important;
    }
    .accessories-Card-button {
        margin: 20px 0px;
    }
}