.waranty-paragraph {
    font-family: "Roboto", Sans-serif;
    font-size: 1em;
    line-height: 1.8em;
    font-weight: 400;
    letter-spacing: 0px;
    padding: 0px 43px;
}

.waranty-section-third{
    background-image: url("https://dectecstorageprod.blob.core.windows.net/website/images/aboutus/AdobeStock_81953062_CLASSIC-GREY-min.jpg");
    background-blend-mode: multiply;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    clip-path: polygon(50% 100%, 100% 5%, 100% 0, 0 0, 0 5%);
    width: 100%;
    position: relative;
    opacity: 0.2;
    background-color: black;
}
@media (max-width:991px ) {
    .waranty-section-third{
        background-image: url("https://dectecstorageprod.blob.core.windows.net/website/images/aboutus/AdobeStock_81953062_CLASSIC-GREY-min.jpg");
        background-blend-mode: multiply;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top center;
        clip-path: polygon(50% 50%, 100% 0, 100% 0, 0 0, 0 0);
        width: 100%;
        position: relative;
        opacity: 0.2;
        background-color: black;
    }
    
}