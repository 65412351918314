/* CSS Document */

@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&family=Roboto:ital@1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400&display=swap');

/* .text_RobotoCondensed{
  font-family: "RobotoCondensed";
}   */

body {
  background: #ffffff;
  margin: 0;
  padding: 0;
}

#container {
  margin: 0 auto;
  max-width: 890px;
}

.toggle,
[id^=drop] {
  display: none;
}

/* Giving a background-color to the nav container. */
nav {
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  width: 100%;
  z-index: 10;
}

nav:hover {
  background-color: rgba(0, 0, 0, 0.7);
  transition: background 2s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}

#logo {
  display: block;
  padding: 7px 40px;
  float: left;
  color: #FFF;
  cursor: pointer;
}

#logo img {
  width: 110px;
}

nav {
  height: 40px;
}

nav:after {
  content: "";
  display: table;
  clear: both;
}

nav ul {
  float: right;
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
}

nav ul li {
  margin: 0px;
  display: inline-block;
}

nav a {
  display: block;
  padding: 14px 20px;
  color: #FFF;
  text-decoration: none;
  font-family: "Roboto Condensed", Sans-serif;
  font-size: 0.8em;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 1.4px;
}

nav ul li ul li:hover {
  background: #00779f;
  color: #FFF !important;
}

nav a:hover {
  transition: .4s;
}

.main-menu:hover {
  color: #00779f;
  transition: .4s;
}

nav ul ul {
  min-width: 174px;
  max-width: 170px;
  width: 156px;
  font-family: "Roboto Condensed", Sans-serif;
  font-weight: 300;
}

nav ul ul li a {
  letter-spacing: 0px !important;
  padding: 12px 20px !important;
  font-weight: 300;
}

.sub-menu li a {
  font-family: "Roboto Condensed", Sans-serif;
  font-weight: 300;
  text-transform: uppercase;
  font-style: normal;
  white-space: nowrap;
  padding: 12px 20px;
  /* font-size: 0.9em; */
}

nav ul ul {
  display: none;
  position: absolute;
}

.child-menu {
  display: inherit;
  background-color: rgba(0, 0, 0, 0.7);
}

.child-menu:hover {
  background: #00779f;
  padding: 12px 20px;
  height: inherit;
}

nav ul ul li {
  float: none;
  display: list-item;
  position: relative;
}

nav ul ul ul li {
  position: relative;
  top: -39px;
  left: 170px;
}

.menu-icon:after {
  content: ' +';
  position: relative;
  left: 4px;
}



.contact-no:hover {
  color: #ffffff !important;
}

.search-icon {
  padding-right: 40px;
  padding-left: 20px;
  color: #ffffff;
  cursor: pointer;
}

.contact-no {
  font-size: 14px !important;
  font-family: "Roboto", Sans-serif !important;
  letter-spacing: 0px !important;
  padding: 12px 20px;
  color: #FFF;
  text-decoration: none;
  font-weight: 400;
}

/* Media Queries
--------------------------------------------- */

@media all and (max-width : 991px) {

  .is-hidden-mobile {
    display: none !important;
  }


  [id^=drop]:checked+ul {
    position: relative;
    width: 100%;
    max-width: 100%;
    transition: all 2s ease-in-out;
  }

  .menu {
    width: 100%;
  }

  nav ul {
    float: left;
    text-align: center;
    /* transform: translate(-0%);
    top: -50%; */
  }

  #logo {
    display: block;
    padding: 0;
    width: 100%;
    text-align: left;
    padding-left: 8%;
    float: none;
    height: 23px;
  }


  #logo img {
    width: 105px;
    height: 22px;
    display: block;
    float: left;
    margin-top: 10px;
  }

  nav {
    margin: 0;
    z-index: 99;
  }

  /* Hide the navigation menu by default */
  /* Also hide the  */
  .toggle+a,
  .menu {
    display: none;
  }

  /* Stylinf the toggle lable */
  .toggle {
    font-family: "Roboto Condensed", Sans-serif;
    /* font-size: 0.9em; */
    letter-spacing: 1.4px;
    font-weight: 300;
    display: block;
    background-color: rgba(0, 0, 0, 0.7);
    color: #FFF;
    text-decoration: none;
    border: none;
  }

  .toggle-btn {
    transform: translate(-50%, 50%);
    left: 50%;
    top: 0px;
    position: absolute;
    background-color: transparent !important;
  }

  .toggle:hover {
    background-color: #00779f;
  }

  /* Display Dropdown when clicked on Parent Lable */
  [id^=drop]:checked+ul {
    display: block;
  }

  /* Change menu item's width to 100% */
  nav ul li {
    display: block;
    width: 100%;
  }

  nav ul ul .toggle,
  nav ul ul a {
    padding: 0 40px;
  }

  nav ul ul ul a {
    padding: 0 80px;
   
  }
nav a{
  display: inline-block;
  padding: 12px 20px;
  color: #FFF;
  text-decoration: none;
  font-family: "Roboto Condensed", Sans-serif;
  font-size: 0.9em;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 1.4px;
}

  nav a:hover,
  nav ul ul ul a:hover {
    background-color: #00779f;
   
  }

  nav ul li ul li .toggle,
  nav ul ul a,
  nav ul ul ul a {
    color: #FFF;
    /* font-size: 0.8em; */
    text-transform: uppercase;
    background: transparent;
  }

  nav ul ul ul li .toggle:hover{
    color: #FFF;
    font-size: 0.9em;
    text-transform: uppercase;
    
  }
  nav ul li ul li:hover{
    background: #00779f;
  }
  /* nav ul li ul li ul li{
    background-color: rgba(0, 0, 0, 0.7);
    background: transparent;
  } */

  /* Hide Dropdowns by Default */
  nav ul ul {
    float: none;
    position: static;
    color: #ffffff;
    /* has to be the same number as the "line-height" of "nav a" */
  }

  /* Hide menus on hover */
  nav ul ul li:hover>ul,
  nav ul li:hover>ul {
    display: none;
  }

  /* Fisrt Tier Dropdown */
  nav ul ul li {
    display: block;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
  }

  nav ul ul ul li {
    position: static;
    background: transparent !important;
    background-color: rgba(0, 0, 0, 0.7) !important;
  
    /* has to be the same number as the "width" of "nav ul ul li" */

  }
/* 
  nav ul ul ul li .toggle{
    background: transparent;
    background-color: rgba(0, 0, 0, 0.7);
  } */

  .contact-no-mob {
    display: block;
    float: right;
    position: relative;
    top: -10px;
    padding: 0px;
    right: 8%;
  }

  nav ul li {
    display: block;
    width: 100%;
  }
  .sub-menu-hover{
    /* background-color: rgba(0, 0, 0, 0.7) !important; */
    background-color:rgba(0, 0, 0, 0.1)
  }

}

@media all and (min-width : 1024px) {
  .is-hidden-desktop{
    display: none !important;
  }

  nav ul ul li:hover .sub-menu-hover {
    display: inherit;
    /* background-color: rgba(0, 0, 0, 0.7); */
  }

  nav ul li:hover .menu {
    display: inherit;
    background-color: rgba(0, 0, 0, 0.7);
  }
}