.image-slider {
   color: white;
   padding: 0px 10px;
}

.box {
   height: 350px;
   width: 100%;
}

.box img {
   width: 100%;
   object-fit: cover;
   height: 100%;
   color: #fff;
   cursor: pointer;
}

.slick-slide div {
   margin-right: 10px;
 }
 .slick-slide img {
   width: 100% !important;
 }

.slick-dots li.slick-active button:before {
   color: gray;
}

.slick-dots li button::before {
   color: gray;
   font-size: .4rem;
}

.slick-prev:before,
.slick-next:before {
   font-family: 'slick';
   font-size: 20px;
   line-height: 1;
   opacity: .75;
   color: #333333;

}

.slick-prev {
   left: 10px !important;
   z-index: 9 !important;
}

.slick-next {
   right: 20px;
   z-index: 9 !important;
}

.slider-box {
   height: 400px;
   width: 100%;
   position: relative;
   cursor: pointer;
}

.image-box {
   width: 100%;
   object-fit: cover;
   height: 100%;
   color: #fff;
   cursor: pointer;
  
}

.text-box {
   position: absolute;
   bottom: 0;
   background: rgba(0, 0, 0, 0.5);
   color: #f1f1f1;
   width: 100%;
   transition: .5s ease;
   opacity: 0;
   font-size: 50px;
   text-align: center;
   height: 100%;  
}

.slider-box:hover .text-box {
   opacity: 1;
   
}

.slider-text{
   color: #fff;
    font-size: 18px;
    position: relative;
    top: 45%;
    opacity: 0.6;
}


@media (max-width: 768px) {
   /* .slick-slide div {
      margin-right: 10px;
    } */

    .slick-slide div{
      margin-right: 0px;
    }
}
