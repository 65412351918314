.card-main-div{
    display: flex;
    flex-wrap: wrap;
    padding-top: 44px;
}
.card-main-div-first{
    flex-basis: 50%;
    position: relative;
    padding-bottom: 6rem;
}

.card__image{
    width: 100%;
    height: 550px;
    object-fit: cover;
}

.card-containts{
    position: absolute;
    top: 0;
    width: 100%;
}
.card-containts-icon-wrapper{
    display: flex;
}

.card-containts-buttons{
    justify-content: flex-start;
    background-color: white;
    padding: 3% 5%;
}

.card-containts-buttons_right{
    justify-content: flex-end;
    background-color: white;
    padding: 3% 5%;
    padding-top: 35px;
}

.card-buttons{
    display: flex;
}

.card__divider-classic{
    border-top: solid;
    border-color: #00779f;
    border-width: 5px;
    width: 10%;
    padding-bottom: 20px;
    margin-left: 1%;
}

.text_border-bottom{
    border-width: 5px !important;
}

.card__description-classic{
    color: #54595f;
    line-height: 1.5em;
    font-weight: 350;
    padding-top: 30px;
}


.classic-Card-button{
    margin-right: 75px;
    margin-left: 10px;
}
.classic-card-icon{
    padding: 10px;
    color: #e5e5e5;
    justify-content: center;
    align-items: center;
    display: flex;
}

.classic-card-text{
    color: #e5e5e5;
    font-size: 30px;
    font-weight: 800;
    font-style: italic;
    padding-left: 15px;
}

.m-l-50 {
    margin-left: 50px !important;
}

.m-r-50 {
    margin-right: 50px !important;
}

.accessories-Card-button2 {
    margin: 20px 14px;
}

.pad-bottom{
    padding-bottom: 5%;
}
@media (max-width: 768px) {

    .card-main-div-first{
        flex-basis: 100%;
    }

    .card__divider-classic{
        margin-left: 3%;
        width: 20%;
    }
    .card-heading-font-size{
        font-size: 1.5em;
    }
  }  