/* .home-Section-First {
    background-image: url("https://dectecstorageprod.blob.core.windows.net/website/images/home/DT-CSL-CoolStep-1.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    background-attachment: fixed;
} */

.home-Section-First {

    background-image: url("https://dectecstorageprod.blob.core.windows.net/website/images/home/DT-CSL-CoolStep-1.jpg");
    height: 100vh;
    background-attachment: initial;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover
}

.section-first-margin {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 15%;
    padding-top: 9%;
}

.section-first-name-first {

    font-size: 3.5rem;
    color: white;
}

.thin-content {
    font-weight: 100 !important;
}


.underline {
    width: 31%;
    border-bottom: solid;
    border-color: #00779f;
    border-width: 25px;
}

.section-first-name-second {
    display: flex;
    font-size: 60px;
    font-weight: 100;
    color: white;
    margin-top: 10px;

}

.bannerSubtitle {
    font-size: 0.85em;
    font-weight: 100;
}

.element-row-second {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.section-first-text {
    display: flex;
    justify-content: right;
    align-items: center;
    text-align: center;
    z-index: 2;
    font-size: 1.2rem;
    color: white;
    padding: 12% 0% 5% 25%;

}

.textShadow {
    text-shadow: 0px 0px 10px rgb(0 0 0 / 30%);
}

.first-btn {
    margin-left: 120px;
    font-size: 18px;
    padding: 20px 40px;
    border-radius: 5px;
    background-color: #5bc0de;
    border: none;
    color: white;
    cursor: pointer;
}

.home-Section-Third {
    background-image: url("https://dectecstorageprod.blob.core.windows.net/website/images/home/become-an-installer-template-min.jpg");
    background-position: bottom right;
    background-size: cover;
    width: 100%;
    background-repeat: no-repeat;
    background-attachment: initial;
    align-items: center;
}

.home-Section-Four {
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    margin-top: 60px;
    margin-bottom: 0px;
    padding: 0px 0px 0px 0px;
    background-color: #333333;
}

.section-four-main-div {
    background: url("https://dectecstorageprod.blob.core.windows.net/website/images/home/DT-CSL-CoolStep-2.jpg") rgba(94, 94, 94);
    background-blend-mode: multiply;
    background-position: center right;
    background-repeat: no-repeat;
    background-size: cover;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
    background-attachment: initial;
    min-height: 50vh;
    display: flex;
}

.Section-four-element-first {
    padding: 15% 0%;
}

.content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.content-wrapper-icon {
    color: #00779f;
    border-color: #00779f;
    display: inline-block;
    line-height: 1;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
    font-size: 50px;
    text-align: center;
    width: 1em;
    height: 1em;
}

.content-wrapper-heading {
    color: #ffffff;
    font-size: 2.5em;
    font-weight: 200;
    text-transform: uppercase;
    letter-spacing: 0px;
    text-shadow: 0px 0px 0px rgb(0 0 0 / 30%);
    padding: 30px 0px 20px 0px;

}

.content-wrapper-para {
    text-align: center;
    color: #ffffff;
    font-size: 14px;
    font-weight: 200;
    line-height: 1.8em;
    letter-spacing: 0px;
    width: 70%;
}

.content-wrapper-parahome {
    text-align: center;
    color: #ffffff;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.8em;
    letter-spacing: 0px;
    width: 80%;
}


.homeparagraph {
    text-align: justify;
    padding-top: 4%;
    width: 50%
}

.homeheading {
    line-height: 1.4;
    font-size: 3.6em !important;
}

@media (max-width: 991px) {

    .section-first-margin {
        align-items: center;
        padding-top: 50%;
        padding-right: 0;
    }

    .section-first-name-first {
        font-size: 4rem;
    }

    .section-first-name-second {
        font-size: 50px;

    }

    .element-row-second {
        padding-right: 0;
        padding-top: 5%;
    }

    .section-first-text {
        font-size: 1.5rem;
        padding: 5%;
    }

    .first-btn {
        font-size: 13px;
        padding: 15px 35px;
        margin-left: 0px;
    }

    .centeralignment {
        align-items: center !important;
        text-align: center;
    }

    .homeheading {
        font-size: 2.6em !important;
        padding-top: 10%;
    }



    .homeparagraph {
        text-align: center;
        padding-top: 4%;
        width: 100%;
        padding-bottom: 30px;
    }

    .rightTextAlign {
        text-align: center !important;
    }
}

@media (max-width: 768px) {

    .section-first-margin {
        align-items: center;
        padding-top: 50%;
        padding-right: 0;
    }

    .section-first-name-first {
        font-size: 2rem;
    }

    .section-first-name-second {
        font-size: 35px;

    }

    .element-row-second {
        padding-right: 0;
        padding-top: 5%;
    }

    .section-first-text {
        font-size: 1.1rem;
    }

    .first-btn {
        font-size: 13px;
        padding: 15px 35px;
    }

    .centeralignment {
        align-items: center !important;
        text-align: center;
    }

    .homeheading {
        font-size: 2.6em !important;
        padding-top: 10%;
    }

    .homeparagraph p {
        margin: 5% 5% 0 5%;
    }
}