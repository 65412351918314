.product-div-wrap{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.elementor {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
}

.elementor-wrap-div{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}


.elementor-wrap-div2{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: center;
}

.elementor-row {
    display: flex;
    flex-direction: row;
}
.elementor-col {
    display: flex;
    flex-direction: column;
}


.left-div{
    align-items: flex-start;
    text-align: justify;
}
.right-div{
    align-items: flex-end;
    text-align: right;
}

.divider {
    display: flex;
    border-top: solid;
    border-color: #00779f;
    border-width: 5px;
    width: 10%;
    margin: 3% 0%;
}

.divider-without-underline {
    margin: 30px 0px;
}

.text-with-border{
        padding-bottom: 15px;
        border-bottom: 5px solid #00779f;
   
}

.str {
    display: flex;
    text-align: center;
    text-transform: capitalize;
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.8em;
}
.heading-title{
    color: #000000;
    font-size: 2.6em;
    font-weight: 100;
    letter-spacing: 1px;
    padding: 0px 9px;
}

.title-bold{
    font-weight: bold;
}

.paragraph {
    color: #54595f;
    font-family: "Roboto", Sans-serif;
    font-size: 0.9em;
    font-weight: 300;
    line-height: 1.8em;
    letter-spacing: 0px;
}
    
.elementor-button {
    display: flex;
    margin: 5%;
    padding: 0px 20px;
}

.btnSecond {
    cursor: pointer;
    transform: scale(1.2);
    transition-duration: .5s;
    font-size: 0.6em;
    text-transform: uppercase;
    letter-spacing: 1px;
    background-color: rgba(0,0,0,0);
    /* border-style: solid;
    border-width: 1px 1px 1px 1px; */
    border:1px solid #000000;
    color:#000;
    padding: 10px 13px 10px 13px;
    border-radius: 2px;
}

.btnSecond:hover {
    background-color: #54595f;
    color: white;
    transition-duration: .4s;
    transform: scale(1);

}

.gallery {
    display: flex;
    flex-direction: column;
    margin:0px 100px 0px 100px;
}

.imagwrap {
    display: flex;
    margin-bottom: 3px;
    justify-content: center;
}


.imagwrap2 {
    display: block;
    margin-bottom: 3px;
    justify-content: center;
}

.herland {
    background-position: bottom right;
    background-size: cover;
    width: 40%;
    background-repeat: no-repeat;
    background-attachment: fixed;
    margin-right: 15px;
    cursor: pointer;
    position: relative;
    margin-bottom: 13px;
}


.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: rgb(11 22 22 / 55%);
}


.herland2 {
    background-position: bottom right;
    background-size: cover;
    width: 45%;
    background-repeat: no-repeat;
    background-attachment: fixed;
    margin-right: 15px;
    cursor: pointer;
    position: relative;
    margin-bottom: 13px;
    float: left;
}


.herland2:hover .first-image {
    opacity: 0.3;
}

.herland2:hover .middle {
    opacity: 1;

}

.herland2 img {
    width: 100%;
}

.herland2:hover .overlay {
    opacity: 1;
}

.herland:hover .first-image {
    opacity: 0.3;
}

.herland:hover .middle {
    opacity: 1;

}

.herland img {
    width: 100%;
}

.herland:hover .overlay {
    opacity: 1;
}


.first-image {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: .5s ease;
    backface-visibility: hidden;
}

.middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}

.new-product-text {
    color: #fff;
    font-size: 20px;
    padding: 16px 32px;
}
.container{
    padding: 10% 0%;
}

@media all and (max-width : 768px) {
    .heading-title{
        font-size: 1.8em;
        line-height: 1.5;
    }
    .divider {
        width: 20%;
        margin: 5% 0%;
    }
    .left-div{
        align-items: center;
        text-align: center;
        margin: 5%;
    }
    .right-div{
        align-items: center;
        text-align: center;
        margin: 5%;
    }
    .container{
        padding: 5% 0%;
    }
    .str {
        display: flex;
        text-align: center;
        text-transform: capitalize;
        font-size: 1rem;
        line-height: 1.8em;
        padding: 0;
    }
    .product-dectec-logo{
        width: 100%;
    }
    .elementor-m-row{
        display: flex;
        flex-direction: row;
    }
    .elementor-m-col {
        display: flex;
        flex-direction: column;
    }

    .herland2{
        width:100%;
        margin-bottom: 20px;
    }
    .gallery {
        margin:0px 100px 0px 100px!important;
    }
    
   
}

@media all and (max-width : 991px) {
    .heading-title{
        font-size: 2em;
        line-height: 1.5;
 
    }
    .left-div{
        align-items: center;
        text-align: center;
        margin: 0%;
    }
    .right-div{
        align-items: center;
        text-align: center;
        margin: 5%;
    }

    .heading-title2{
        display: contents!important;
    }

    .gallery {
        margin:0px 100px 0px 140px;
    }
    .elementor-wrap-div2{
        align-items: center!important;
        text-align: center!important;
    }

    .text-with-border{
        padding-bottom: 5px;
        border-bottom: 5px solid #00779f;
   
}
  
  
}

@media all and (max-width : 376px) {
    .heading-title{
        font-size: 1.8em;
        line-height: 1.5;
    }
}