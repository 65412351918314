.wrapper{
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(10rem, 16rem));
    gap: 2rem!important;
    justify-content: center;
    padding: 50px 0;
}


.card{
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    transition: transform 200ms ease-in;
}

.card__img{
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    transition: transform 200ms ease-in;
    max-width: 100%;
    height: auto;
    object-fit: cover;
}

.card__img:hover{
    opacity: .5;
}

.card__title{
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    transition: transform 200ms ease-in;
    text-align: center;
    font-size: 20px;
    color: #000000;
}

.card__title a {
    text-decoration: none;
    color: #000000;
    font-family: "Roboto", Sans-serif;
}

.card__description-technical{
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    transition: transform 200ms ease-in;
    font-family: "Roboto", Sans-serif;
    color: #54595f;
    font-size: 1em;
    font-weight: 300;
    line-height: 1.8em;
    letter-spacing: 0px;
    text-align: center;
}




