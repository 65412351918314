.paragraph-faqs-accord{
    color: gray;
    font-family: "Roboto", Sans-serif;
    font-size: 0.85em;
    font-weight: 300;
    line-height: 1.8em;
    letter-spacing: 0px;
    padding: 0 20px;
}
.paragraph-faqs-accord-list{
    color: gray;
    font-family: "Roboto", Sans-serif;
    font-size: 0.85em;
    font-weight: 300;
    line-height: 1.8em;
    letter-spacing: 0px;
    padding: 0 2%;
}

.accordation{
    margin-bottom: 20px;
    box-shadow: 0px 0px 30px 0px rgb(0 0 0 / 12%);
    padding: 15px;
}
.cardLink{
    font-weight: bold;
    color: black;
}
.cardLink-anchor:hover{
    color: black;
}
.cart-heading{
    font-size: 16px;
    font-weight: 750;
    text-transform: uppercase;
    color: black;
}
.accordation-wrapper{
    width: 100%;
}

.faq-Cart-heading-bold{
    color: #000000;
    font-family: "Roboto", Sans-serif;
    font-size: 2.0em;
    font-weight: 100;
    letter-spacing: 0.5px;
    text-shadow: 0px 0px 0px rgb(0 0 0 / 30%);
}
.divider-faq{
    border-top: solid;
    border-color: #00779f;
    border-width: 5px;
    width: 30%;
    display: flex;
    margin: 3% 0%;
}