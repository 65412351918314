.membranes-section-second{
    background-color: #54595f;
    background-image: url('https://dectecstorageprod.blob.core.windows.net/website/images/products/Dec-7-Grafton-Night-image-1-v1.3-HighRes.jpg');
    background-position: bottom center;
    background-size: cover;
    position: relative;
    height: 85vh;
    clip-path: polygon(50% 98%, 100% 53%, 100% 0, 0 0, 0 53%);
}
 
.membranes-second-text-wrap{
    display: flex;
    text-align: center;
    flex-direction: column;
}

.heading-title-new{
    color: #00779f;
    font-size: 2.5em;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-style: italic;
}

.membranes-section-second-title{
    position: absolute;
    background: rgb(0, 0, 0);
    background: rgba(122, 122, 122, 0.82);
    width: 100%;
    height:100%;
    opacity: 0.7;
}

.membranes-section-second-divider{
    border-top: solid;
    border-color: #00779f;
    border-width: 5px;
    width: 5%;
    display: flex;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.membranes-second-heading-title{
    color: #ffffff;
    font-size: 2.6em;
    font-weight: 200;
    margin-bottom: 10px;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.membranes-second-heading-title2{
    color: #ffffff;
    font-size: 2.6em;
    font-weight: 200;
    margin-bottom: 10px;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.membranes-section-third{
    background-color: #54595f;
    background-image: url('https://dectecstorageprod.blob.core.windows.net/website/images/products/CoolStep-19.jpg');
    background-position: bottom center;
    background-size: cover;
    position: relative;
    height: 60vh;
    clip-path: polygon(50% 98%, 100% 58%, 100% 0, 0 0, 0 58%);
}

.image-wrapper-first{
    position: relative;
}

.image-wrapper-second{
    margin-top: -18%;
    margin-left: 43%;
}

.captionFig{
    width: 25%;
    margin-top: 3%;
    padding-left: 17%;
    color: #54595f;
}

.captionFig-second{
    margin-top: 3%;
    margin-left: 32%;
    color: #54595f;
}

.padding-left{
    padding-left: 20%;
}

@media all and (max-width : 991px) {
    .padding-left{
        padding-left: 0%;
    } 
    .membranes-second-heading-title {
        font-size: 3em;
        left: 33%;
        transform: translate(0%, -50%);
    }

    .membranes-second-heading-title2 {
        font-size: 2em;
        left: 0%;
        transform: translate(0%, -50%);
        right: 0;
        text-align: center;
    }
  
    .membranes-section-second {
        clip-path: polygon(50% 98%, 100% 85%, 100% 0, 0 0, 0 85%);
        height:40vh;
    }
    .membranes-section-second-divider {
        width: 15%;
        top: 50%;
    }
    .membranes-section-third{
        clip-path: polygon(50% 98%, 100% 85%, 100% 0, 0 0, 0 85%);
        height:40vh;
    }


}

@media all and (max-width : 768px) {
.membranes-second-heading-title{
    color: #ffffff;
    font-size: 1.6em;
    font-weight: 200;
    margin-bottom: 10px;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
}


}