.products-section-first {
    background-image: url("https://dectecstorageprod.blob.core.windows.net/website/images/products/DT-CSL-Sandalwood-1.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom center;
    background-attachment: initial;
    height: 100vh;
    width: 100%;
}

.product-logo-text {
    margin-bottom: 1.6rem;
}

.products-wrap-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    justify-content: center;
    margin-bottom: 100px;
}

.product-dectec-logo {
    width: 100%;
}

.product-text {
    display: flex;
    font-size: 2em;
    font-weight: 300;
    color: white;
    font-family: 'Roboto', sans-serif;
    margin-bottom: 50px;

}

.products-section-third {
    background: linear-gradient(0deg, rgb(11 11 22 / 43%), rgb(11 11 22 / 43%)), url(https://dectecstorageprod.blob.core.windows.net/website/images/products/1.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom center;
    background-attachment: initial;
    width: 100%;
    display: flex;
    align-items: center;

}

.products-wrapper {
    display: flex;
    flex-direction: column;
}

.sectionSecond {
    background: white;
    padding: 40px 0px 40px 0px;
    height: 60vh;
    width: 100%;
    background-repeat: no-repeat;
    background-attachment: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
}

.products-elementor-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.products-elementor {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.products-text-wrap {
    display: flex;
    padding: 10px;
    padding-bottom: 30px;
}

.products-image-text-wrap {
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.products-elementor-heading-title {
    color: white;
    font-family: "Roboto", Sans-serif;
    font-size: 2.5em;
    font-weight: 200;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    text-shadow: 0px 0px 0px rgb(0 0 0 / 30%);
    padding-left: 15px;
}

.products-image-heading-title {
    color: #3e9fd0;
    font-family: "Roboto", Sans-serif;
    font-size: 1.2em;
    font-weight: 150;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    text-shadow: 0px 0px 0px rgb(0 0 0 / 30%);
    margin-bottom: 10px;
    width: 100%;
    text-align: center;
}

.products-image-heading-para {
    text-align: center;
    font-family: "Roboto", Sans-serif;
    font-size: 0.8em;
    font-weight: 100;
    line-height: 1.6em;
    color: rgb(216, 217, 219);
}

.products-paragraph {
    font-size: 1em;
    font-family: 'Roboto', sans-serif;
    margin-bottom: 20px !important;
    line-height: 1.8em;
    text-align: center;
    color: white;
}

.products-paragraph-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 94%;
    padding-bottom: 50px;
}

.products-widget-wrap {
    display: flex;
}

.products-image-wrap {
    display: flex;
}

.products-image-first {
    background-position: top right;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    cursor: pointer;
    position: relative;
    margin: 10px;
}

.products-image-first:hover .products-image {
    opacity: 0.3;
}

.products-image-first:hover .products-image-middle {
    opacity: 1;

}

.products-image-first img {
    width: 100%;
}

.products-image-first:hover .products-image-overlay {
    opacity: 1;
}

.products-image-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: rgba(0, 0, 0, 0.7);
}

.products-image {
    opacity: 1;
    display: block;
    width: 100%;
    height: 500px;
    transition: .5s ease;
    backface-visibility: hidden;
    object-fit: cover;
}

.products-image-middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
}

.products-image-text {
    color: #fff;
    font-size: 20px;
    padding: 16px 32px;
}

.products-image-bottom-title {
    color: #ffffff;
    font-size: 1.5em;
    font-weight: 300;
    text-transform: uppercase;
    margin-top: 20px;
    text-align: center;
}

@media (max-width:991px) {

    .product-dectec-logo {
        width: 90%;
        text-align: center;
        margin: 0 5%;
    }

    .product-text{
        text-align: center;
        font-size: 1.6em;
        padding-top: 30px;
    
    }

    

}