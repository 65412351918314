  .classic-section-third {
    background: url("https://dectecstorageprod.blob.core.windows.net/website/images/products/kyler-trautner-686951-unsplash-min.jpg") rgb(41, 41, 41);
    background-blend-mode: multiply;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    background-attachment: initial;
    /* clip-path: polygon(50% 96%, 100% 73%, 100% 0, 0 0, 0 73%); */
    height: 100vh;
    width: 100%;
    position: relative;
    opacity: 0.94;
  }

  .coolstep-section-third {
    background: url("https://dectecstorageprod.blob.core.windows.net/website/images/products/photo-1508282353155-05eb4eac138d-min.jpg");
    background-blend-mode: multiply;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom center;
    background-attachment: initial;
    /* clip-path: polygon(50% 96%, 100% 73%, 100% 0, 0 0, 0 73%); */
    height: 100vh;
    width: 100%;
    position: relative;
    opacity: 0.99;
  }

 
  .btnLearnMore {

    font-weight: 400;
    cursor: pointer;
    transform: scale(1);
    transition-duration: .5s;
    font-size: 0.6em;
    text-transform: uppercase;
    letter-spacing: 1px;
    fill: #000000;
    color: #000000;
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #000;
    padding: 10px 13px;
    border-radius: 2px;

  }
  

  .btnLearnMore:hover {
    background-color: #0078a1;
    color: white;
    transition-duration: .5s;
    transform: scale(1.2);
    border: 1px solid #0078a1;
  }


  .classic-section-sixth {
    background-color: rgba(122, 122, 122, 0.36);
    height: 25vh;
    width: 100%;
    clip-path: polygon(50% 100%, 100% 7%, 100% 0, 0 0, 0 7%);
  }

  .classic-section-sixth-text {
    padding: 0 8%;
    text-align: left;
    color: #54595f;
    font-family: "Roboto", Sans-serif;
    font-size: 0.8em;
    font-weight: 300;
    line-height: 1.8em;
    letter-spacing: 0px;
  }

  .classic-section-third-divider {
    margin-left: 0px !important;
  }

  .classic-section-third-divider-right {
    margin-right: 0px !important;
  }

  .slider-wrapper {
    padding: 70px 0;
    overflow-x: hidden;
  }

  .text-right {
    text-align: right !important;
  }

  .justiy-end {
    justify-content: end !important;
  }

  .margin-o {
    margin-right: 0px !important;
  }

  .margin-left {
    margin-left: auto !important;
    margin-right: 2%;
  }

  .justify-content {
    justify-content: flex-end;
  }

  .classline-items {
    align-items: flex-start;
    /* margin-left: 10%; */
    position: relative;
  }

  .classline-img {
    margin-right: 15px;
    position: relative;
  }

  .membrane-info-title {
    text-align: end;
  }

  .parent {
    position: relative;
    overflow: hidden;
  }

  .child-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: -1;
    right: -1px;
    background-color: #3d3d3d;
    opacity: 0.9;
  }


    @media (max-width: 991px) {

      .classline-items {
        align-items: center;
        text-align: center;
        margin-left: 0 !important;
      }

      .classline-img {
        margin-right: 0px;
      }

      .membrane-info-title {
        text-align: start;
      }

      .classic-section-third {
        height: 80vh !important;
      }

      .coolstep-section-third {
        height: 85vh !important;
      }
    }

    @media (max-width: 768px) {

      .classline-items {
        align-items: center;
        text-align: center;
      }

      .classline-img {
        margin-left: 0;
      }

      .membrane-info-title {
        text-align: start;
      }

      .btnLearnMore-res {
        margin: 10%;
      }

      .classic-section-third {
        height: 90vh !important;
      }

      .coolstep-section-third {
        height: 90vh !important;
      }
    }

    @media (max-width: 376px) {
      .classic-section-third {
        height: 110vh !important;
      }

      .coolstep-section-third {
        height: 110vh !important;
      }

    }