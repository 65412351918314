.image-contianer {
    position: relative;
    width: 100%;
    height: 100vh;
    background-position: center bottom;
    background-size: cover;
    background-repeat: no-repeat;
    margin: 0 auto;
}

.title-Card-overlay {
    position: absolute;
    background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.3);
    color: #f1f1f1;
    bottom: 0;
    height: 100vh;
    width: 100%;
}

.title-Card-Left {
    position: absolute;
    display: flex;
    justify-content: flex-start;
    transform: translate(-0%, -50%);
    z-index: 9 !important;
    top: 50%;
    width: 100%;
}

.title-Card-Right {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    transform: translate(-0%, -50%);
    z-index: 9 !important;
    top: 50%;
    width: 100%;
}

.card-heading {
    color: white;
    font-family: "Roboto", Sans-serif;
    font-size: 4em;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 200;
    padding: 0px 9px;
}

.underline-text {
    border-bottom: solid;
    border-color: #00779f;
    border-width: 25px;
    margin-bottom: 30px;
}

.card-paragraph-left {
    text-align: justify;
    color: #ffffff;
    font-family: "Roboto", Sans-serif;
    font-size: 1.0em;
    font-weight: 300;
    line-height: 1.8em;
    letter-spacing: 0px;
    margin: 0;
    padding: 9px;
    padding-top: 45px;
    width: 50%;
}

.card-paragraph-right {
    text-align: right;
    color: #ffffff;
    font-family: "Roboto", Sans-serif;
    font-size: 1.0em;
    font-weight: 300;
    line-height: 1.8em;
    letter-spacing: 0px;
    margin: 0;
    padding: 9px;
    padding-top: 45px;
}

.card-heading-wrap_Coloum {
    display: flex;
    flex-direction: column;
}

.card-heading-wrap_Row {
    display: flex;
    flex-direction: row;
}


.card-wrap-left {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-left: 15%;
}

.card-wrap-right {
    display: flex;
    flex-direction: column;
    text-align: right;
    margin-right: 15%;
}

.padding-top {
    padding-top: 50px;
}

@media (max-width:991px) {

    .underline-text {
        border-width: 10px !important;
    }

    .image-contianer {
        width: auto;
    }

    .title-Card-Left {
        top: 50%;
        width: 100%;
        justify-content: center;
    }

    .title-Card-Right {
        justify-content: center;
    }

    .card-heading {
        font-size: 50px;
    }

    .card-paragraph-left
   {
        font-size: 1em;
        text-align: center;
        padding: 40px;
        width: 90%;
    }

    .card-paragraph-right{
        font-size: 1em;
        text-align: center;
        padding: 50px;
        width: auto;
    }

    .card-heading-wrap_Row {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .card-heading-wrap_Coloum {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .card-wrap-left {
        margin: 0 !important;
    }

    .card-wrap-right {
        margin: 0 !important;
    }
}

@media (max-width:768px) {
    .card-heading {
        font-size: 25px;
    }
    .card-paragraph-left{
        padding: 20px;
    }
    .card-heading {
        font-size: 32px;
    }
}

@media (max-width:360px) {
    .card-heading {
        font-size: 25px;
    }

    
}